import React from 'react';

export const BackIcon = ({ width, height, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill={color ? color : "#000"} width={width ? width : "24"} height={height ? height : "24"} viewBox="0 0 24 24"><path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" /><path fill="none" d="M0 0h24v24H0z" /></svg>
);

export const CloseIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 241.171 241.171" fill={props.color}> <path d="M138.138,120.754l99.118-98.576c4.752-4.704,4.752-12.319,0-17.011c-4.74-4.704-12.439-4.704-17.179,0 l-99.033,98.492L21.095,3.699c-4.74-4.752-12.439-4.752-17.179,0c-4.74,4.764-4.74,12.475,0,17.227l99.876,99.888L3.555,220.497 c-4.74,4.704-4.74,12.319,0,17.011c4.74,4.704,12.439,4.704,17.179,0l100.152-99.599l99.551,99.563 c4.74,4.752,12.439,4.752,17.179,0c4.74-4.764,4.74-12.475,0-17.227L138.138,120.754z" /> </svg>
);

export const AuditIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} fill={props.fill} viewBox="0 0 16 16"><path d="M 7 0 C 6.4534686 0 6 0.4534686 6 1 L 3.5 1 C 2.6774686 1 2 1.6774686 2 2.5 L 2 12.5 C 2 13.322531 2.6774686 14 3.5 14 L 11.5 14 C 12.322531 14 13 13.322531 13 12.5 L 13 2.5 C 13 1.6774686 12.322531 1 11.5 1 L 9 1 C 9 0.4534686 8.5465314 0 8 0 L 7 0 z M 7 1 L 8 1 L 8 2 L 7 2 L 7 1 z M 3.5 2 L 6 2 L 6 3 L 9 3 L 9 2.5 L 9 2 L 11.5 2 C 11.781469 2 12 2.2185314 12 2.5 L 12 12.5 C 12 12.781469 11.781469 13 11.5 13 L 3.5 13 C 3.2185314 13 3 12.781469 3 12.5 L 3 2.5 C 3 2.2185314 3.2185314 2 3.5 2 z M 9.3964844 6.2714844 L 7 8.6679688 L 5.6035156 7.2714844 L 4.8964844 7.9785156 L 7 10.082031 L 10.103516 6.9785156 L 9.3964844 6.2714844 z"/></svg>
);