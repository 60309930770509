const firebase = window.firebase;

const firebaseConfig = {
  apiKey: "AIzaSyDIknPVsXT-jhjOXgE4kBYPI_AavGxGFtQ",
  authDomain: "karlekstanken-copy-2.firebaseapp.com",
  databaseURL: "https://karlekstanken-copy-2-default-rtdb.firebaseio.com",
  projectId: "karlekstanken-copy-2",
  storageBucket: "karlekstanken-copy-2.appspot.com",
  messagingSenderId: "414131883244",
  appId: "1:414131883244:web:062feeb8effe74add7a9c9",
  measurementId: "G-PQ0F28HBX5",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();
//TODO: disable when testing, enable when publishing
analytics.setAnalyticsCollectionEnabled(true);

const db = app.firestore();
db.enablePersistence()
  .then(() => {
    console.log("firestore offline persistence enabled");
  })
  .catch((e) => console.log(e));

const auth = app.auth();
const functions = app.functions("europe-west1");
// Executes cloud functions from locally running functions emulator
//functions.useFunctionsEmulator("http://localhost:5001");
const EmailAuthProvider = firebase.auth.EmailAuthProvider;
const ServerTimestamp = firebase.firestore.Timestamp;
const FieldValue = firebase.firestore.FieldValue;
export {
  app,
  analytics,
  db,
  auth,
  functions,
  EmailAuthProvider,
  ServerTimestamp,
  FieldValue,
};
