const MyStrings = {
  appName: "WHOLENESS",
  privacyPolicyUrl:
    "https://wholeness.se/onewebmedia/Personuppgiftspolicy%20WH.pdf",
  licenseAndTermsUrl:
    " https://wholeness.se/onewebmedia/Allma%CC%88nna%20anva%CC%88ndarvillkor%20fo%CC%88r%20webbapp%20WHOLENESS.pdf",
  copyright: "Copyright © Wholeness Sweden AB 2022",
  homeTitle: "Hem",
  signInTitle: "Logga in",
  signUpTitle: "Registrera",
  forgotPasswordTitle: "Återställ lösenord",
  settingsTitle: "Inställningar",
  languageTestTitle: "Utvecklingsenkät",
  logoAlt: "Wholeness Logo",
  privacyPolicy: "Personuppgiftspolicy",
  licenseAndTerms: "Användarvillkor",
  you: "Du",
  and: "och",
  yourLoveLang: "Ditt primära utvecklingsområde",
  loveLang: "primära utvecklingsområde",
  add: "Lägg till",
  signOut: "Logga ut",
  cancel: "Avbryt",
  yourEmail: "Din e-postadress",
  name: "Namn",
  licens: "Licens",
  since: "Sedan",
  through: "Till och med",
  partner: "Vän",
  firstName: "Förnamn",
  lastName: "Efternamn",
  email: "E-postadress",
  password: "Lösenord",
  firstNamePlaceholder: "Skriv in ditt förnamn",
  lastNamePlaceholder: "Skriv in ditt efternamn",
  emailPlaceholder: "Skriv in din e-postadress",
  passwordPlaceholder: "Skriv in ditt lösenord",
  signedOut: "Du är utloggad",
  signIn: "Logga in",
  createAccount: "Skapa konto",
  exercises: "ÖVNINGAR",
  loading: "Laddar in...",
  print: "Skriv ut",
  readLessBtn: "- LÄS MINDRE",
  readMoreBtn: "+ LÄS MER",
  homeGuideText: "Börja här med detta gratistema",
  InstallationGuideModal: {
    notice:
      "Notera: Du kan komma att behöva logga in på nytt när du startar appen från hemskärmen första gången.",
    youCanInstallWith:
      "Du kan lägga till WHOLENESS på din hemskärm med webbläsaren",
    doLikeThis: "Gör så här:",
    press: "Tryck på",
    iOSInstructions: {
      step1: "(dela) som finns i webbläsarens botten eller topp",
      step2: 'Välj "Lägg till på hemskärmen"',
      step3: "Lägg till",
    },
    androidInstructions: {
      step1: "som finns i webbläsarens topp",
      step2: 'Välj "Lägg till på startskärmen"',
      step3: "Lägg till",
    },
    otherInstructions: {
      step1:
        "som finns i webbläsarens högra sida av adressfältet, bredvid bokmärksymbolen.",
      step2: "Installera",
    },
  },
  PurchaseSuccess: {
    header: "Ditt köp lyckades!",
    lead: "Ni har nu licens och tillgång till allt i WHOLENESS.",
    text: "Varsågod och fortsätt där ni slutade och gör nästa övning",
    notice:
      "Notera: Ni kan komma att behöva ladda om sidan eller logga in på nytt för att alla avsnitt ska bli upplåsta första gången.",
    btn: "Till alla avsnitt",
  },
  NotFound: {
    text: "Sidan hittades inte",
    btn: "Ta mig till hemsidan",
  },
  AddPartnerAlert: {
    text: "WHOLENESS är en dubbelapp för dig och din vän. Du har ännu inte lagt till din vän.",
    addBtn: "Lägg till nu",
  },
  PurchaseBanner: {
    text: "Köp Licens så får ni tillgång till hela WHOLENESS",
    btn: "TILL KÖP",
  },
  InstallBanner: {
    text: "Lägg till WHOLENESS på hemskärmen",
    btn: "Lägg till",
  },
  Settings: {
    title: "Inställningar",
    signedInAs: "Inloggad som",
    youHaveNoPartner: "Du har inte lagt till någon vän ännu",
    youHaveNoLicense: "Du har ingen licens",
    changePasswordBtn: "Ändra lösenord",
    removePartnerBtn: "Ta bort vän",
    removePartnerNote:
      "Observera att du inte kan ta bort din vän när du har licens",
    deletAccountBtn: "Avsluta konto",
  },
  Auth: {
    passwordResetSent:
      "Vi har skickat en länk till din e-postadress där du kan återställa ditt lösenord",
    passwordResetInfo:
      "Ange den e-postadress som du använde när du registrerade dig. Vi skickar en länk där du kan återställa ditt lösenord",
    passwordResetSubmit: "Skicka",
    signInBtn: "Logga in",
    signUpBtn: "Registrera",
    forgotPasswordBtn: "Glömt lösenord?",
    passwordFieldLabel: "Lösenord (minst 6 tecken)",
    bySigningUp: "Genom att registrera mig godkänner jag",
    thePrivacyPolicy: "Personuppgiftspolicyn",
    theLicenseAndTerms: "Användarvillkoren",
  },
  PurchaseModal: {
    title: "Lås upp WHOLENESS",
    byContinuing: "Genom att fortsätta med betalningen godkänner du våra",
    notSignedIn: "Du behöver först ett konto innan du kan köpa licens.",
    signUp: "Skapa ett konto här",
    checkoutBtn: "Betala med Stripe",
  },
  MyNavBar: {
    signInBtn: "LOGGA IN",
    signOutBtn: "LOGGA UT",
    signUpBtn: "SKAPA KONTO",
    settingsBtn: "INSTÄLLNINGAR",
    aboutBtn: "OM WHOLENESS",
    showDescriptionBtn: "Visa beskrivning",
    addPartnerBtn: "LÄGG TILL VÄN",
    loveLangTestNotDone: "Du har ännu inte gjort Utvecklingsenkäten",
    haveNotDoneLangTest: "har ännu inte gjort Utvecklingsenkäten",
    doTestBtn: "Gör enkäten",
    contactUsText: "Eventuella frågor är du välkommen att ställa till ",
  },
  AddPartnerModal: {
    title: "Lägg till vän",
    title2: "Bjud in vän",
    addSuccess: "har nu lagts till som din vän",
    inviteSuccess: "Inbjudan har skickats till",
    closeBtn: "Stäng",
    undeterminedViewText: "Har din vän ett konto på WHOLENESS?",
    undeterminedViewYesBtn: "Ja, lägg till",
    undeterminedViewNoBtn: "Nej, bjud in",
    addViewFormLabel:
      "Ange din väns e-postadress som hen registrerade sig med på WHOLENESS ",
    addViewFormSubmit: "Lägg till",
    inviteViewText1:
      "Bjud in din vän till WHOLENESS nedan. Hen blir tillagd som din partner i WHOLENESS när hen har registrerat sig med länken som skickas med inbjudningsmejlet.",
    inviteViewFormLabel: "Ange din väns e-postadress",
    inviteViewFormSubmit: "Bjud in",
  },
  ChangePasswordModal: {
    title: "Ändra lösenord",
    success: "Ditt lösenord har nu ändrats",
    currentPasswordLabel: "Ditt nuvarande lösenord",
    currentPasswordPlaceholder: "Skriv in ditt nuvarande lösenord",
    passwordLabel: "Nytt lösenord (minst 6 tecken)",
    passwordPlaceholder: "Skriv in ditt nya lösenord",
    repeatPasswordLabel: "Bekräfta nytt lösenord",
    repeatPasswordPlaceholder: "Skriv in ditt nya lösenord igen",
    closeBtn: "Stäng",
    submitBtn: "Spara",
  },
  RemovePartnerModal: {
    areYouSure: "Är du säker på att du vill ta bort",
    asPartner: "som vän?",
    removeBtn: "Ta bort",
  },
  DeleteAccountModal: {
    success1: "Ditt konto är nu avslutat",
    success2: "Era konton är nu avslutade",
    title1: "Är du säker på att du vill avsluta ditt konto?",
    title2: "Är du säker på att du vill avsluta ditt och",
    account: "s konto?",
    warningText: "OBS: Om du avslutar ditt konto kommer er licens att upphöra.",
    deleteBtn: "Avsluta",
  },
  LoveLanguageTest: {
    title: "Utvecklingsenkäten",
    instructions:
      "Ta reda på vad du kan börja med genom att välja det påstående i varje grupp som bäst stämmer in på dig",
    langSaved: "(Ditt primära utvecklingsområde har sparats)",
    testNotComplete: "Du måste välja ett påstående i varje grupp",
    submitBtn: "Färdig",
  },
  Errors: {
    invalidEmail: "Ogiltig e-postadress",
    fieldRequired: "Fältet kan inte vara tomt",
    passwordTooShort: "Lösenordet kan inte vara mindre än 6 tecken",
    wrongPassword: "Du har angivit fel lösenord.",
    wrongCurrentPassword: "Du har angivit fel nuvarande lösenord. Försök igen",
    passwordMismatch: "Lösenorden stämmer inte överens",
    emailAlreadyInUse:
      "Det finns redan en användare med den angivna e-postadressen.",
    unknown: "Ett okänt fel inträffade. Var god försök igen.",
    unknownTryLater: "Ett okänt fel inträffade. Var god försök igen senare.",
    userNotFound: "Det finns ingen användare med den angivna e-postadressen.",
    receiverAlreadyHasPartner: "Användaren har redan en vän i WHOLENESS.",
    cannotAddSelf: "Du kan inte lägga till dig själv.",
    couldNotDeleteAccount:
      "Ett okänt fel inträffade och ditt konto har inte blivit fullständigt borttaget. Var god försök igen senare för att få bort ditt konto fullständigt.",
  },
};

export default MyStrings;
